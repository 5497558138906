<template>
  <div>
    <div v-if="closeOfDayListLoading">
      <div class="text-center px-10 py-10">
        <v-progress-circular color="primary" indeterminate></v-progress-circular>
      </div>
    </div>
    <div v-if="!closeOfDayListLoading">
      
      <hb-empty-state v-if="!isPropertyEnabled" :message="'Close of Day is disabled for selected property'">
      </hb-empty-state>
      <div v-else-if="closeOfDayList && closeOfDayList.length > 0">
        <hb-data-table-header>
          <template v-slot:description>
          </template>
        </hb-data-table-header>
        <hb-card title="Close of Day" class="my-6 pb-1">
          <hb-data-table :headers="headers" :items="closeOfDayList" :item-class="getRowClass">
            <template v-slot:header.actions="{ header }">
              <div class="d-flex justify-center content-center">{{ header.text }}</div>
            </template>

            <template v-slot:item.method="{ item }">
              <span><hb-icon color="#101318">{{ getIconName(item.method) }}</hb-icon>
                {{ getMethodName(item.method) }}</span>
              <hb-tooltip v-if="item.method === 'cleaning_deposit'">
                <template v-slot:item>
                  <HbIcon small class="mr-1" mdi-code="mdi-information" />
                </template>
                <template v-slot:body>
                  Cleaning Deposit collected from the auction's winning bidder.
                </template>
              </hb-tooltip>
            </template>
            <template v-slot:item.total_amount="{ item }">
              <span class="cod-label">{{ item.total_amount }}</span>
            </template>
            <template v-slot:item.actual="{ item }">
            <v-text-field
            id="actual"
            type="number"
            single-line
            step="0.01"
            name="actual"
            placeholder="——"
            v-model.number="item.actual"
            style="padding-bottom: 16px;"
            :class="getRowClass(item)" 
            data-vv-as="Actual Amount"
            :maxlength="decimalPointLimit"
            @input="decimalPointLimiter(item)"
            :hide-details="!errors.has('actual')"
            :error-messages="errors.first('actual')"
            :error="errors.collect('actual').length > 0"
            :readonly="!(['cash', 'check', 'cleaning_deposit'].includes(item.method) && showCloseOfDayBtn)"
              />
            </template>
            <template v-slot:item.difference="{ item }">
              <span class="cod-label" v-if="item.actual || item.actual == 0">{{ parseFloat((item.difference 
              ? item.difference 
              : item.actual ? item.total_amount - item.actual : item.total_amount) || 0).toFixed(2) }}</span>
            </template>

            <template v-slot:footer>
              <hb-bottom-action-bar cancel-off>
                <template v-slot:right-actions>
                  <hb-btn color="primary" :disabled="!showCloseOfDayBtn" @click="submitCloseTheDay" :loading="show_loading_spinner">Close the
                    Day</hb-btn>
                </template>
                <template v-slot:left-actions>
                  <div v-if="lastCODBy || lastCODAt" class="text-body-2 hb-text-light">
                    Last Close Of Day By: {{ lastCODBy }} @ {{ lastCODAt | formatDateCustom("MMM DD, YYYY hh:mm A") }}
                  </div>
                </template>
              </hb-bottom-action-bar>
            </template>
          </hb-data-table>

        </hb-card>
      </div>
    </div>
    <hb-modal v-model="showConfirmationDialog" size="medium" title="Confirm Close the Day" confirmation
      @close="onCloseConfirmationDialog" show-help-link>
      <!-- Confirmation Modal -->
      <template v-slot:content>
        <div class="px-6 py-4">
          Are you sure you want to close this day?
        </div>
        <div class="px-6 pb-4">
        </div>
      </template>
      <template v-slot:right-actions>
        <hb-btn color="primary" @click="saveCloseOfDay">Confirm</hb-btn>
      </template>
    </hb-modal>
    <!-- Restricted Permission warning popup // -->

  </div>
</template>

<script type="text/babel">
import { mapGetters } from "vuex";
import api from '../../assets/api.js';
import { notificationMixin } from '@/mixins/notificationMixin.js';

export default {
  name: "CloseOfDayList",
  mixins: [notificationMixin],
  data() {
    return {
      headers: [
        { text: "Payment Type", value: "method", sortable: false },
        {
          text: "Recorded",
          value: "total_amount",
          sortable: false
        },
        { text: "Actual", value: "actual", sortable: false, width:150 },
        { text: "Difference", value: "difference", sortable: false },
      ],
      showConfirmationDialog: false,
      show_loading_spinner: false,
      closeOfDayList: [],
      closeOfDayListLoading: true,
      showCloseOfDayBtn: true,
      lastCODBy: '',
      lastCODAt: '',
      property_date: '',
      isPropertyEnabled: true,
      decimalPointLimit: ''
    };
  },
  props: {
    activeTab: {
      type: Object
    },
    property_id: {
      type: String
    }
  },
  components: {
  },
  computed: {
    ...mapGetters({
      hasPermission: 'authenticationStore/rolePermission',
      allProperties: 'propertiesStore/properties',
    }),

  },
  watch: {
    property_id() {
      this.fetchData();
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.closeOfDayListLoading = true;
        let path = api.PROPERTIES + this.property_id + '/transactions';
        let data = await api.get(this, path);
        const { transactions, property_enabled=true } = data;
        this.isPropertyEnabled = property_enabled;
        if(transactions) {
          this.showCloseOfDayBtn = data.close_of_day;
          this.lastCODBy = data.created_by;
          this.lastCODAt = data.created_at;
          this.property_date = data.property_date;
          transactions.push({ method: 'Total', total_amount: '0', actual: '', difference: '' })
          const sortOrder = ["cash", "check", "card", "directdebit", "ach", "eftpos", "Total", "cleaning_deposit"];
          // Sort the transactions array based on the defined order
          transactions.sort((a, b) => {
            return sortOrder.indexOf(a.method) - sortOrder.indexOf(b.method);
          });
          this.closeOfDayList = Array.isArray(transactions) ? JSON.parse(JSON.stringify(transactions)) : [];
          this.updatedCloseOfDayList(this.closeOfDayList);
        }
        this.closeOfDayListLoading = false;
      } catch (error) {
        console.error(`Error fetching close of day data:`, error);
        throw error;
      }


    },
    decimalPointLimiter(item) {
      let {actual} = item;
      this.decimalPointLimit = ''
      if (actual.toString()?.includes(".")) {
        actual = parseFloat(actual).toFixed(2)
        this.decimalPointLimit = actual.length
      }
      this.updateDifference(item);
    },
    updateDifference(item) {
      const recordedValue = parseFloat(item.total_amount || 0).toFixed(2);
      const actualValue = parseFloat(item.actual || 0).toFixed(2);
      const difference = recordedValue - actualValue;
      item.difference = difference ? difference.toString() : '0';
      this.updatedCloseOfDayList();
    },
    updatedCloseOfDayList() {
      // Calculate totals for total_amount, actual, and difference
      const totals = this.closeOfDayList.reduce(
        (acc, item) => {
          if (item.method !== 'Total' && item.method !== 'cleaning_deposit') {
            acc.total_amount += parseFloat(item.total_amount || 0);
            acc.actual += parseFloat(item.actual || 0);
            acc.difference += item.difference
              ? parseFloat(item.difference)
              : item.actual
                ? (parseFloat(item.total_amount) - parseFloat(item.actual))
                : item.total_amount
                  ? parseFloat(item.total_amount)
                  : 0;
          }
          return acc;
        },
        { total_amount: 0, actual: 0, difference: 0 }
      );
      this.closeOfDayList.forEach(item => {
        if (item.method === 'Total') {
          item.total_amount = parseFloat(totals.total_amount).toFixed(2).toString();
          item.actual = parseFloat(totals.actual).toFixed(2).toString();
          item.difference = totals.difference ? totals.difference.toString() : '0';
        }
      });
      return this.closeOfDayList;
    },
    getRowClass(item) {
      return item.method === 'Total' ? 'grey lighten-3' : '';
    },
    getIconName(name) {
      switch (name) {
        case 'cash':
          return 'mdi-cash-multiple';
        case 'check':
          return 'mdi-checkbook';
        case 'card':
          return 'mdi-credit-card-outline';
        case 'directdebit':
          return 'mdi-bank';
        case 'ach':
          return 'mdi-checkbook';
        case 'eftpos':
          return 'mdi-credit-card-outline';
        case 'directdeposit':
            return 'mdi-checkbook';
        case 'cleaning_deposit':
          return 'mdi-broom';
        default:
          return '';
      }
    },
    getMethodName(name) {
      switch (name) {
        case 'cash':
          return 'Cash';
        case 'check':
          return 'Check';
        case 'card':
          return 'Credit Card';
        case 'ach':
          return 'ACH';
        case 'directdebit':
          return 'Direct Debit';
        case 'eftpos':
          return 'EFTPOS';
        case 'directdeposit':
          return 'Direct Deposit';
        case 'cleaning_deposit':
          return 'Cleaning Deposit Amount*';
        default:
          return name;
      }
    },
    submitCloseTheDay() {
      this.showConfirmationDialog = true;
    },
    async saveCloseOfDay() {
      this.showConfirmationDialog = false;
      const filteredResult = this.closeOfDayList
        .filter(item => item.actual && item.method != 'Total')
        .reduce((acc, item) => {
          acc[`${item.method}_amount`] = parseFloat(item.actual);
          return acc;
        }, {});
      let path = api.PROPERTIES + this.property_id + '/closing-day';
      let filename = 'Close of Day Deposits Report';
      let property = this.allProperties.find(p => p.id == this.property_id);
      filename += ` - ${property.name}`;
      if(this.property_date){
        filename += ` - ${this.property_date}`;

      }
      let data = {
        type: 'daily-deposits',
        format: 'xlsx',
        timeZone: 'Asia/Karachi',
        name: 'Close of Day Deposits Report',
        unit_group_profile_id: null,
        property_group_id: null,
        property_ids: [this.property_id],
        roles_id: ['WEj67IYBe3'],
        collected_amounts: filteredResult,
      };
      try {
          let response = await this.$http.post(path, data, {
            responseType: "arraybuffer"
          });
          let blob = new Blob([response.data], {
            type: response.headers.get("content-type")
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();
          this.show_loading_spinner = false;
        } catch (err) {
          this.show_loading_spinner = false;
          this.showMessageNotification({
            description: err.statusText ? err.statusText : err
          });
        }
       this.fetchData();
    },
    onCloseConfirmationDialog() {
      this.showConfirmationDialog = false;
    },
  }
};
</script>

<style scoped>
 .cod-label {
  font-size: 14px !important;
 }
</style>
